<template lang='pug'>
v-dialog(v-if='dialog' v-model='dialog' width='500')
    v-card
        v-card-title {{ dialog.title }}
        v-card-text {{ dialog.text }}
        v-divider
        v-card-actions
            v-spacer
            v-btn(:color='dialog.danger ? "primary" : "default"' text @click='onCancel') {{ dialog.cancelText || 'Annuleren' }}
            v-btn(:color='dialog.danger ? "red" : "primary"' text @click='onConfirm') {{ dialog.confirmText || 'Bevestigen' }}
</template>

<script>
  export default {
    name: 'Dialog',
    computed: {
        dialog: {
            get() {
                return this.$store.state.dialog;
            },
            set(val) {
                this.$store.commit('setDialog', val);
            }
        }
    },
    methods: {
        onCancel() {
            if (this.dialog.onCancel) this.dialog.onCancel();
            this.destroy();
        },
        onConfirm() {
            if (this.dialog.onConfirm) this.dialog.onConfirm();
            this.destroy();
        },
        destroy() {
            this.dialog = false;
        }
    }
  }
</script>
