<template lang='pug'>
  v-app
    Dialog
    v-main
      router-view
    v-footer(app)
      v-row(justify='center' align='center')
        p.ma-3 © 2022 - Simplex Connect
</template>

<script>
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'App',
  components: {
    Dialog
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.v-footer {
  background-color: white !important;
  color: grey !important;
}
.loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
</style>