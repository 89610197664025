import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'overview',
    component: () => import(/* webpackChunkName: "overview" */ '../views/Overview.vue')
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import(/* webpackChunkName: "templates" */ '../views/Templates.vue')
  },
  {
    path: '/new',
    name: 'new',
    component: () => import(/* webpackChunkName: "new" */ '../views/New.vue')
  },
  {
    path: '/edit/:id',
    name: 'edit',
    props: true,
    component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue')
  },
  {
    path: '/generate',
    name: 'generate',
    component: () => import(/* webpackChunkName: "generate" */ '../views/Generate.vue')
  },
  {
    path: '/generate/edit/:project/:id',
    name: 'generateEdit',
    props: true,
    component: () => import(/* webpackChunkName: "generate_edit" */ '../views/Edit.vue')
  },
  {
    path: '/append/:project/:id',
    name: 'append',
    props: true,
    component: () => import(/* webpackChunkName: "append" */ '../views/Append.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.sessionId) {
    await store.dispatch('login', to.query);
  }
  next();
});

export default router
