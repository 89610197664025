import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex)

const API_URL = 'https://pbcl.simplexconnect.nl';
// const API_URL = 'http://localhost:3000' // for testing

const store = new Vuex.Store({
  state: {
    dialog: false,
    sessionId: null,
    templates: null,
    notGeneratedProjects: null,
    generatedProjects: null,
    projects: null,
    questionnaires: {},
    fullQuestionnairesByProject: {},
    fullQuestionnairesById: {},
    attachments: {}
  },
  getters: {
  },
  mutations: {
    setDialog(state, dialog) {
      state.dialog = dialog;
    }
  },
  actions: {
    async login(context, payload) {
      // For testing:
      // payload = {
      //  publickey: 'PUBLIC'
      // }

      const res = await axios.post(`${API_URL}/login`, payload);
      context.state.sessionId = res.data;
      axios.defaults.headers.common['session-id'] = res.data;
    },
    async getTemplates(context) {
      const res = await axios.get(`${API_URL}/getTemplates`);
      context.state.templates = res.data;
      return res.data;
    },
    async createTemplate(context, template) {
      await axios.post(`${API_URL}/createTemplate`, template);
    },
    async updateTemplate(context, template) {
      await axios.put(`${API_URL}/updateTemplate`, template);
    },
    async deleteTemplate(context, templateId) {
      await axios.delete(`${API_URL}/deleteTemplate?id=` + templateId);
    },
    async getProjects(context, filter) {
      if (filter) {
        if (filter === 'generated') {
          const res = await axios.get(`${API_URL}/getProjects?filterOn=generated`);
          context.state.generatedProjects = res.data;
          return res.data;
        } else if (filter === 'notgenerated') {
          const res = await axios.get(`${API_URL}/getProjects?filterOn=notgenerated`);
          context.state.notGeneratedProjects = res.data;
          return res.data;
        }
      } else {
        const res = await axios.get(`${API_URL}/getProjects`);
        context.state.projects = res.data;
        return res.data;
      }
    },
    async createQuestionnaire(context, template) {
      await axios.post(`${API_URL}/createQuestionnaire`, template);
    },
    async appendQuestionnaire(context, template) {
      await axios.post(`${API_URL}/appendQuestionnaire`, template);
    },
    async getQuestionnaires(context, projectId) {
      const res = await axios.get(`${API_URL}/getQuestionnaires?projectId=` + projectId);
      context.state.questionnaires[projectId] = res.data;
      context.state.questionnaires = JSON.parse(JSON.stringify(context.state.questionnaires));
      return res.data;
    },
    async getQuestionnaire(context, filter) {
      if (filter.projectId) {
        const res = await axios.get(`${API_URL}/getQuestionnaire?projectId=` + filter.projectId);
        context.state.fullQuestionnairesByProject[filter.projectId] = res.data;
        context.state.fullQuestionnairesByProject = JSON.parse(JSON.stringify(context.state.fullQuestionnairesByProject));
        return res.data;
      } else if (filter.questionnaireId) {
        const res = await axios.get(`${API_URL}/getQuestionnaire?questionnaireId=` + filter.questionnaireId);
        context.state.fullQuestionnairesById[filter.questionnaireId] = res.data;
        context.state.fullQuestionnairesById = JSON.parse(JSON.stringify(context.state.fullQuestionnairesById));
        return res.data;
      }
    },
    async getAttachmentList(context, projectId) {
      const res = await axios.get(`${API_URL}/getAttachmentList?projectId=` + projectId);
      context.state.attachments[projectId] = res.data;
      context.state.attachments = JSON.parse(JSON.stringify(context.state.attachments));
      return res.data;
    },
    async getAttachments(context, payload) {
      const res = await axios.get(`${API_URL}/getAttachments?projectId=${payload.project}${ payload.query ? '&' + payload.query : '' }`);
      return res.data;
    },
    async getTempQuestionnaire(context, { projectId }) {
      const res = await axios.get(`${API_URL}/tempQuestionnaire?projectId=` + projectId);
      return res.data;
    },
    async saveTempQuestionnaire(context, { id, projectId, data }) {
      await axios.post(`${API_URL}/tempQuestionnaire?id=` + id + '&projectId=' + projectId, data);
    }
  },
  modules: {
  }
})

export default store;